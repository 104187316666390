<template>
  <div class="flex items-center justify-between p mb-4 px-2 py-4 md:py-2 bg-gradient-to-r from-robin-egg-blue-400 dark:from-russian-violet-500 to-russian-violet-500 dark:to-robin-egg-blue-600">
    <div class="flex items-center">
      <button
        class="mr-4 flex flex-col items-center justify-center"
        @click="goBack"
      >
        <i class="i-heroicons-chevron-left text-2xl"></i>
      </button>
      <h2 class="font-semibold text-2xl">
        <slot />
      </h2>
    </div>
    <div class="flex items-center">
      <slot name="right" />
    </div>
  </div>
</template>

<script setup lang="ts">
const router = useRouter();

// Go back in such a way that vue detects the change and updates the page
const goBack = () => {
  router.back();
};
</script>

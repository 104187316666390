<template>
  <!-- Default layout containing a top bar that stretches the width of the page, a left side bar, and the main content area -->
  <div class="flex flex-col min-h-screen max-w-[100vw]">
    <!-- Mobile only topbar -->
    <div class="flex flex-row fixed top-0 left-0 right-0 z-30 md:hidden">
      <div
        class="w-full bg-oxford-blue-700 dark:bg-black-50 lg:border-b border-teal-500 text-gray-800 dark:text-gray-100 flex flex-row items-center justify-center p-2"
      >
        <!-- Logo -->
        <div class="flex flex-row items-center">
          <ULink :to="{ name: 'index' }" class="flex flex-row items-center">
            <UColorModeImage :light="logoLight" :dark="logoDark" class="h-6" />
            <span class="ml-2 font-semibold text-xl tracking-tight sr-only"
              >Joystick Office</span
            >
          </ULink>
        </div>
      </div>
    </div>

    <div class="flex flex-row fixed bottom-0 md:top-0 shadow-xl md:shadow-none md:bottom-auto left-0 right-0 z-30">
      <!-- Top bar -->
      <div
        class="w-full bg-oxford-blue-700 dark:bg-black-50 lg:border-b border-teal-500 text-gray-800 dark:text-gray-100 flex flex-row-reverse md:flex-row items-center justify-between px-4 pt-4 pb-4 gap-12"
        :class="{
          'pb-8': $pwa?.isPWAInstalled
        }"
      >
        <!-- Logo -->
        <div class="flex flex-row items-center gap-12 w-full">
          <ULink :to="{ name: 'index' }" class="hidden md:flex flex-row items-center gap-4">
            <UColorModeImage :light="logoLight" :dark="logoDark" class="h-8" />
            <span class="ml-2 font-semibold text-xl tracking-tight sr-only"
              >Joystick Office</span
            >
          </ULink>

          <!-- Search bar -->
          <div class="flex flex-row items-center gap-2 w-full md:w-auto">
            <UInput
              v-model="appStore.globalSearch"
              name="q"
              placeholder="Search..."
              icon="i-heroicons-magnifying-glass-20-solid"
              autocomplete="off"
              size="lg"
              color="gray"
              :ui="{ wrapper: 'relative w-full md:w-auto', icon: { trailing: { pointer: '' } } }"
              @keyup.enter="search"
              autocapitalize="off"
              autocorrect="off"
              spellcheck="false"
            >
              <template #trailing>
                <UButton
                  v-show="appStore.globalSearch !== ''"
                  color="teal"
                  variant="link"
                  icon="i-heroicons-arrow-right-20-solid"
                  :padded="false"
                  @click="search"
                />
              </template>
            </UInput>
          </div>
        </div>

        <!-- Mobile menu button -->
        <UButton
          @click="toggleMobileMenu"
          :icon="
            mobileMenuOpen === true
              ? 'i-heroicons-x-mark'
              : 'i-heroicons-bars-3'
          "
          size="lg"
          class="md:hidden"
        />
      </div>
    </div>

    <div class="flex flex-row flex-1">
      <div
        class="fixed left-0 top-0 bottom-0 z-20 pb-[73px] pt-[40px] md:pt-[73px] md:pb-0 overflow-y-auto bg-white dark:bg-taupe-gray-950 transition-all duration-300 ease-in-out"
        :class="mobileMenuOpen === true ? 'w-[300px]' : 'w-0 md:w-[300px]'"
      >
        <div class="flex flex-col flex-grow py-2">
          <UVerticalNavigation
            ref="navigation"
            :links="links"
            :ui="{
              strategy: 'override',
              base: `${verticalNavigation.base} px-4 py-2.5 before:rounded-none no-underline`,
              rounded: '',
              inactive: 'font-medium text-xl text-gray-600 dark:text-gray-400',
              active: 'font-extrabold text-xl text-robin-egg-blue-700 dark:text-robin-egg-blue-500',
              icon: {
                base: 'flex-shrink-0 w-6 h-6',
                inactive: 'text-gray-600 dark:text-gray-400',
                active: 'text-teal-500 dark:text-teal-500',
              },
            }"
          />
        </div>
      </div>

      <!-- Main content area -->
      <div class="flex-1 transition-all duration-300 ease-in-out pb-[73px] pt-[40px] md:pt-[73px] md:pb-0"
        :class="mobileMenuOpen === true ? 'pl-[300px]' : 'pl-0 md:pl-[300px]'">
        <PageTitle v-if="pageTitle">{{ pageTitle }}</PageTitle>
        <div class="min-w-[375px] flex flex-col flex-grow max-w-[100vw] h-full">
          <template v-if="route.meta.noContainer">
            <slot />
          </template>
          <template v-else>
            <section class="px-4 pt-4 pb-12 max-w-[100vw]">
              <slot />
            </section>
          </template>
        </div>
      </div>
    </div>
  </div>

  <UNotifications />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import type { Link } from "#ui/types";
import { verticalNavigation } from "#ui/ui.config";
import UVerticalNavigation from '#ui/components/navigation/VerticalNavigation.vue';

import logoDark from "@/assets/images/logo-light-background.svg";
import logoLight from "@/assets/images/logo-dark-background.svg";
import { useAppStore } from "~/store";

const route = useRoute();
const router = useRouter();
const colorMode = useColorMode();
const mobileMenuOpen = ref(false);
const appStore = useAppStore();
const navigation = ref<typeof UVerticalNavigation | null>(null);

const pageTitle = computed(() => {
  const title = route.meta.title;
  return title;
});

useHead({
  title: computed(() => {
    const title = pageTitle.value;
    return title ? `${title} | Joystick Office` : "Joystick Office";
  }),
})

const isDark = computed({
  get () {
    return colorMode.value === 'dark'
  },
  set () {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
  }
})

const themeToggle = () => {
  isDark.value = !isDark.value
};

const links = computed<Array<Link | undefined>>(() => [
    {
      label: "Home",
      icon: "i-heroicons-home",
      to: { name: "index" },
      active: route.name === "index",
    },
    {
      label: "Reporting",
      icon: "i-heroicons-chart-bar",
      to: { name: "reporting" },
      active: route.name === "reporting",
    },
    {
      label: "Media Review",
      icon: "i-heroicons-play-circle",
      to: { name: "media-review" },
      badge: appStore.hasMediaForReview ? appStore.mediaForReviewCount.toString() : undefined,
      active: route.name === "media-review",
    },
    {
      label: "Media",
      icon: "i-heroicons-film",
      to: { name: "media" },
      active: route.name === "media"
    },
    {
      label: "Stream Approvals",
      icon: "i-heroicons-check-circle",
      to: { name: "stream-approvals" },
      badge: appStore.hasStreamApprovalsForReview ? appStore.streamApprovalsForReviewCount.toString() : undefined,
      active: route.name === "stream-approvals" || route.name === "stream-approvals-id",
    },
    {
      label: "Tag Approvals",
      icon: "i-heroicons-tag",
      to: { name: "tag-approvals" },
      badge: appStore.hasTagsForReview ? appStore.tagsReviewCount.toString() : undefined,
      active: route.name === "tag-approvals",
    },
    {
      label: "Users",
      icon: "i-heroicons-users",
      to: "/users",
      active: route.name === "users" || route.name === "users-id",
    },
    {
      label: "Streams",
      icon: "i-heroicons-wifi",
      to: "/streams",
      active: route.name === "streams"
    },
    {
      label: "Transactions",
      icon: "i-heroicons-banknotes",
      to: "/transactions",
      active: route.name === "transactions" || route.name === "transactions-id",
    },
    {
      label: "Subscriptions",
      icon: "i-heroicons-credit-card",
      to: "/subscriptions",
      active: route.name === "subscriptions",
    },
    {
      label: "Arrests",
      icon: "i-heroicons-no-symbol",
      to: "/arrests",
      active: route.name === "arrests"
    },
    {
      label: "Fingerprints",
      icon: "i-heroicons-finger-print",
      to: "/fingerprints",
      active: route.name === "fingerprints"
    },
    {
      label: "Payment Approvals",
      icon: "i-heroicons-check-circle",
      to: "/payment-approvals",
      badge: appStore.hasPaymentsForReview ? appStore.paymentsReviewCount.toString() : undefined,
      active: route.name === "payment-approvals" || route.name === "payment-approvals-id",
    },
    {
      label: "Payouts",
      icon: "i-heroicons-currency-dollar",
      to: "/payouts",
      active: route.name === "payouts" || route.name === "payouts-view-method-date",
    },
    {
      label: "Send Notification",
      icon: "i-heroicons-bell",
      to: "/send-notification",
      active: route.name === "send-notification",
    },
    {
      label: "Restricted Domains",
      icon: "i-heroicons-globe-alt",
      to: "/restricted-domains",
      active: route.name === "restricted-domains",
    },
    {
      label: "Bot Applications",
      icon: "i-heroicons-bolt",
      to: "/bot-applications",
      active: route.name === "bot-applications" || route.name === "bot-applications-id",
    },
    {
      label: "Streaming Servers",
      icon: "i-heroicons-server-stack",
      to: "/streaming-servers",
      active: route.name === "streaming-servers",
    },
    {
      label: "App Settings",
      icon: "i-heroicons-cog",
      to: "/app-settings",
      active: route.name === "app-settings" || route.name === "app-settings-id",
    },
    undefined,
    {
      label: "Logout",
      icon: "i-heroicons-arrow-left-on-rectangle",
      to: "/auth/logout",
    },
    {
      label: "Goto Main Site",
      icon: "i-heroicons-arrow-down-on-square",
      to: "https://joystick.tv",
    },
    {
      label: "Toggle Theme",
      icon: isDark.value ? "i-heroicons-moon" : "i-heroicons-sun",
      click: themeToggle,
    },
  ]);

async function search() {
  await router.push({
    name: "users",
    query: {
      query: appStore.globalSearch,
    },
  });

  appStore.globalSearch = "";
  await router.go(0);
}

function toggleMobileMenu() {
  if (mobileMenuOpen.value === false) {
    mobileMenuOpen.value = true;
  } else {
    mobileMenuOpen.value = false;
  }
}

// When any link in the navigation is clicked, close the mobile menu
// TODO: This is kinda hacky. Would be nice to find a better solution.
onMounted(() => {
  if (!navigation.value || !navigation.value.$el) {
    return;
  }

  const navEl = navigation.value.$el;
  const links = navEl.querySelectorAll("a");
  links.forEach((link: HTMLAnchorElement) => {
    link.addEventListener("click", () => {
      mobileMenuOpen.value = false;
    });
  });
});
</script>
